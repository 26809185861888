.oxbridge_members {
    &__filter {
        margin-bottom: 20px;
        .ant-btn {
            height: 40px;
        }
    }

    .teacher_code {
        position: relative;
        padding-left: 10px;
        font-weight: 700;
        &__position {
            position: absolute;
            height: 70px;
            top: -24px;
            left: -16px;
            font-weight: 500;
            writing-mode: vertical-lr;
            text-align: center;
            border-radius: 8px 0px 0px 8px;
            text-transform: uppercase;
            font-size: 12px;
            background: @info-color;

            &.native {
                background: @primary-color;
                color: @white;
            }
        }
    }

    .contract_status {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        padding-left: 16px;
        border-radius: 0 8px 8px 0;
        &.expired {
            background-color: rgba(@error-color, 0.25);
        }

        &.nodata {
            background-color: rgba(@warning-color, 0.25);
        }
    }
}
