.ant-menu-title-content a {
    text-transform: uppercase;
    font-weight: 500;
}
.sider {
    &__logo {
        font-size: 20px;
        height: 100px;
        font-weight: bold;
        color: #fff;
        img {
            height: 70px;
        }
    }
    .menu-item-select-language {
        position: absolute;
        bottom: 12px;
        width: 90%;
    }
    .ant-menu-root {
        .ant-menu-submenu-selected {
            background-color: @sub-color;
            opacity: 1;
            border-radius: 7px;
            .ant-menu-title-content {
                color: @white;
            }
            .ant-menu-submenu-title {
                margin-top: 0px;
                margin-bottom: 0px;
                background-color: @sub-color;
            }
        }
        .ant-menu-item,
        .ant-menu-submenu-title {
            padding: 0px 0px 0px 12px !important ;
            width: 100%;
            height: 50px;
            line-height: 50px;
            text-transform: uppercase;
            border-radius: 7px;
            color: #fafafa;
            font-weight: 500;
            font-size: 16px;
            letter-spacing: 0.5px;
            background-color: rgba(@white, 0.2);
            transition: all 0.15s ease;
            overflow: unset;
            a {
                font-weight: 500;
                font-size: 16px;
                color: @white;
            }
            &:hover {
                background-color: @sub-color;
                opacity: 1;
                color: @primary-color;
            }

            &.ant-menu-item-selected {
                opacity: 1;
                background-color: @sub-color !important;
                position: relative;
                margin-left: 10px;
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 50px;
                    background-color: #3a4559;
                    top: 0;
                    left: -18px;
                    opacity: 1;
                    border-radius: 0 3px 3px 0;
                    transform: scaleY(1);
                }
            }
        }
    }
}

.error-page {
    height: 100%;
    .ant-result-title {
        font-size: 30px;
        font-weight: 600;
    }
}

.app_loading {
    position: absolute;
    height: 100%;
    width: 100%;
    font-size: 40px;
    background: #33333333;
    color: @primary-color;
    top: 0;
    left: 0;
    z-index: 9999;
}

#root {
    height: 100vh;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
}

img {
    max-width: 100%;
}

.popup {
    background-clip: padding-box;
    background-color: @white;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    left: 0px;
    list-style-type: none;
    margin: 0;
    outline: none;
    padding: 0;
    position: fixed;
    text-align: left;
    top: 0px;
    overflow: hidden;
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.popup li {
    clear: both;
    color: @dark;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    margin: 0;
    padding: 5px 12px;
    transition: all 0.3s;
    white-space: nowrap;
    -webkit-transition: all 0.3s;
}

.popup li:hover {
    background-color: rgba(@sub-color, 0.33);
}

.popup li > i {
    margin-right: 8px;
}

.item--right {
    float: right;
    text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.max-line-3,
.max-line-3 .ant-card-meta-description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.empty-img {
    height: 100%;
    width: 100%;
    background-color: rgba(@sub-color, 0.33);
    color: rgba(@sub-color, 0.75);
    min-height: 200px;
    font-size: 40px;
    .ant-typography {
        font-size: 14px;
        text-align: center;
    }
}

.fc-media-screen {
    height: 100%;
}
.ant-layout-footer {
    position: relative;

    .app-version {
        height: 100%;
        position: absolute;
        right: 5px;
        top: 0;
        text-align: right;
        font-size: 10px;
    }
}
