.grid_chart {
    max-height: 250px;
    overflow-y: scroll;
    width: 100%;
    transition: all 0.3s ease;
    position: relative;
    padding-top: 35px;
    padding-bottom: 5px;

    &__items {
        height: 25px;
        width: 25px;
        border-radius: 3px;
        background-color: rgba(@success-color, 0.05);
        margin: 2px;
        cursor: pointer;
        &.__day {
            width: 50px;
            text-align: center;
            background: rgba(@sub-color, 0.02);
            color: @sub-color;
        }
        &.__month {
            width: 120px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            background: transparent;
            color: @sub-color;
        }
        each(range(1, 10, 1), {
            &.__level-@{index} {
                background: rgba(@success-color, @value * 0.2);
            }
        });

        &.__level-0:hover {
            background-color: rgba(@success-color, 0.1);
        }
    }
    &__labels {
        position: absolute;
        width: 1510px;
        height: 35px;
        top: 0px;
        left: 52px;
    }
    /* width */
    &::-webkit-scrollbar {
        height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(@sub-color, 1);
    }

    &:hover {
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(@sub-color, 0.66);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(@sub-color, 0.99);
        }
    }
}
