.ant-btn {
    &.btn-hover-text {
        > .anticon + span {
            width: 0px;
            overflow: hidden;
            height: 0;
            margin: 0;
            transition: all 0.3s ease;
        }

        &:hover {
            > .anticon + span {
                width: 150px;
                overflow: visible;
                margin-left: 8px;
            }
        }
    }
}

body {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

// Layout
.ant-layout {
    height: 100%;
}
.ant-layout-content {
    max-height: calc(100vh - 40px);
    height: 100%;
    overflow-y: scroll;
    position: relative;
    padding: 24px;
    &::-webkit-scrollbar {
        width: 0px;
        height: 100%;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: @primary-color;
        border-radius: 4px;
    }
}

.fc .fc-scroller-liquid-absolute {
    &::-webkit-scrollbar {
        width: 7px;
        height: 100%;
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background: @primary-color;
        border-radius: 4px;
    }
}

.ant-layout-footer {
    height: 40px;
    padding: 0px 24px;
    font-size: 12px;
    border-top: 1px solid rgba(@sub-color, 0.15);
}

.ant-layout-sider-children {
    width: 90%;
}

.ant-layout-header {
    background: rgba(@primary-color, 0.2);
    border-bottom: 1px solid @primary-color;
    box-shadow: 0px 1px 2px @primary-color;
    z-index: 3;
    padding: 0 24px;
}

.ant-message {
    transition: all 1s ease;
    animation-name: fadeIn;
    animation-duration: 0.4s;
}

.ant-message-notice {
    padding: 8px 0px !important;
}

.ant-message-notice-content {
    border-radius: 5px;
    width: 500px;
    padding: 0;
    height: 45px;

    .ant-message-custom-content {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: @white;
        border-radius: 5px;
        .anticon {
            display: none;
        }
    }

    .ant-message-error {
        background: @error-color;
    }
    .ant-message-success {
        background: @success-color;
    }
    .ant-message-warning {
        background: @warning-color;
    }
    .ant-message-info {
        background: @info-color;
    }
}

.ant-form-item-label label {
    text-transform: uppercase;
    font-size: 14px;
    color: #2d2d2d;
}
.creator-form {
    padding-bottom: 40px;
    .item__submit {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        margin: 0 !important;

        .ant-btn {
            border-radius: 0;
            height: 45px;
            border: 0;
            font-weight: 600;
            letter-spacing: 1px;
            &:hover,
            &:focus,
            &:active {
                background-color: @success-color;
            }
        }
    }
}

.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.7);
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
    width: 100%;
}

// .ant-menu-submenu-title:hover {
//   background-color: #00c368 !important;
// }

.ant-layout {
    background-color: rgba(@sub-color, 0.02);
}

.ant-input {
    &.input_disabled {
        border: none;
        pointer-events: none;
    }
    &.input_editing {
        border: 1px solid #d9d9d9;
        pointer-events: all;
    }
}
.ant-avatar {
    cursor: pointer;
}
.notification_item {
    height: auto;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 10px 25px 10px 15px;
    transition: all 0.4s ease;
    cursor: pointer;
    position: relative;
    opacity: 0.8;
    .notification_icon {
        font-size: 30px;
        margin: 0px 10px;
    }
    .notification_content {
        width: 100%;
    }

    &.SUCCESS {
        background-color: rgba(@success-color, 0.2);
        border: 1px solid rgba(@success-color, 0.5);
        box-shadow: 0px 2px 4px rgba(@success-color, 0.5);
        border-left: 8px solid @success-color;
    }
    &.error {
        background-color: rgba(@error-color, 0.2);
        border: 1px solid rgba(@error-color, 0.5);
        box-shadow: 0px 2px 4px rgba(@error-color, 0.5);
        border-left: 8px solid @error-color;
    }
    &.warning {
        background-color: rgba(@warning-color, 0.2);
        border: 1px solid rgba(@warning-color, 0.5);
        box-shadow: 0px 2px 4px rgba(@warning-color, 0.5);
        border-left: 8px solid @warning-color;
    }
    &.INFOMATION,
    &.PROCESS {
        background-color: rgba(@info-color, 0.2);
        border: 1px solid rgba(@info-color, 0.5);
        box-shadow: 0px 2px 4px rgba(@info-color, 0.5);
        border-left: 8px solid @info-color;
    }
    .notification_name {
        font-size: 18px;
        font-weight: bold;
    }
    small {
        text-align: right;
        width: 100%;
        display: block;
        font-style: italic;
        padding-right: 10px;
    }

    &:hover {
        opacity: 1;
    }
    &.unread {
        opacity: 1;
    }
    &.unread:after {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background-color: @info-color;
        right: 5px;
        top: 5px;
    }
}

// table
.ant-table-column-sorter,
.ant-table-filter-trigger {
    color: @white;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: rgba(@white, 0.33);
}

.styled_table {
    .ant-table {
        background: transparent;
    }
    table {
        border-collapse: separate;
        border-spacing: 0 10px;
        .ant-table-thead > tr > th {
            text-align: left;
            text-transform: uppercase;
            height: 45px;
        }
        .ant-table-thead > tr > th:first-child {
            border-radius: 8px 0 0 8px;
        }
        .ant-table-thead > tr > th:last-child {
            border-radius: 0 8px 8px 0;
        }
        .ant-table-tbody > tr > td {
            border-top: 1px solid @white;
            background: @white;
        }
        .ant-table-tbody > tr > td:first-child {
            border-radius: 8px 0 0 8px;
        }
        .ant-table-tbody > tr > td:last-child {
            border-radius: 0 8px 8px 0;
        }

        .ant-table-row.ant-table-row-level-0 {
            background-color: @white;
            color: @dark;
            height: 70px;
            text-align: left;
            border-radius: 8px;
            border: 1px solid rgba(@dark, 0.2);
            margin-bottom: 10px;
            box-shadow: 0px 2px 3px 2px rgba(@primary-color, 0.33);
            transition: all 0.3s ease;
            cursor: pointer;
            &:hover {
                box-shadow: 0px 3px 6px 1px rgba(@primary-color, 0.7);
                background-color: rgba(@primary-color, 0.2);
            }
        }

        .ant-table-thead th.ant-table-column-sort {
            background: rgba(@primary-color, 0.7);
            &:hover {
                background: rgba(@primary-color, 0.7);
            }
        }

        .ant-table-thead th.ant-table-column-has-sorters:hover {
            background: rgba(@primary-color, 0.4);
        }

        .ant-table-filter-trigger.active {
            color: #bfbfbf;
        }
    }
}

.ant-modal-header,
.ant-drawer-header {
    position: relative;
    background-color: rgba(@primary-color, 0.5);
    background-image: linear-gradient(45deg, rgba(@sub-color, 0.99) 50%, rgba(@primary-color, 0.66) 50% 85%, rgba(@primary-color, 0.33) 85% 100%);
    overflow: hidden;
    z-index: 1;
    min-height: 50px;
    .ant-modal-title,
    .ant-drawer-title {
        color: @white;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .ant-drawer-extra .ant-btn:hover {
        background: rgba(@error-color, 0.2);
    }
}

.ant-modal-footer {
    display: flex;
    justify-content: center;
    background-color: rgba(@sub-color, 0.2);
}
.ant-drawer-content {
    border-radius: 7px 7px 0 0;
}

.ant-drawer-body {
    padding: 24px 12px 24px 24px;
    .ant-form-item {
        margin-bottom: 12px;
    }
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        display: none;
    }
}

.ant-btn-primary {
    background: @sub-color;
    border-color: @sub-color;
}

.ant-descriptions-title,
.ant-typography,
.ant-form-item-label label {
    color: @sub-color;
    font-weight: 500;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 12px 14px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
    background-color: rgba(@primary-color, 0.33);
    color: @sub-color;
    font-weight: 500;
}

.ant-tag {
    line-height: 24px;
    letter-spacing: 0.5px;
}

.ant-image {
    overflow: hidden;
}

.ant-list {
    width: 100%;

    .ant-list-item-hover {
        transition: 0.3s ease;
        border-radius: 10px;
        background-color: rgba(@primary-color, 0.15);
        margin-bottom: 8px;
        .ant-typography,
        .ant-list-item-meta-title {
            margin-bottom: 0;
        }
        &:hover {
            background-color: rgba(@primary-color, 0.25);
            cursor: pointer;
        }
    }
}

.ant-divider-horizontal {
    margin: 12px 0;
}

.ant-card {
    position: relative;
    overflow: hidden;
    height: 100%;
    transition: all 0.25s ease;
    border-radius: 10px;
    .ant-card-body {
        padding: 12px;
        .ant-card-meta-title {
            text-transform: uppercase;
            font-weight: 500;
        }
    }
}
.ant-card-bordered {
    border-color: rgba(@sub-color, 0.11);
    .ant-card-head {
        background-image: linear-gradient(45deg, rgba(@sub-color, 0.99) 70%, rgba(@sub-color, 0.66) 30%);
        color: rgba(@white, 0.88);
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-weight: 600;
    }
    .ant-card-cover {
        height: 200px;
        overflow: hidden;
        img {
            object-fit: cover;
            height: 100%;
        }
    }
    &:hover {
        border-color: rgba(@sub-color, 0.33);
        box-shadow: 0 1px 2px -2px rgba(@sub-color, 0.16), 0 3px 6px 0 rgba(@sub-color, 0.12), 0 5px 12px 4px rgba(@sub-color, 0.1);
    }

    .ant-card-extra {
        float: right;
        margin-right: -7px;
        padding: 0px 0;
        .ant-btn {
            color: rgba(@sub-color, 0.99);
            &:hover {
                background-color: rgba(@white, 0.53);
            }

            .anticon {
                font-size: 18px;
            }
        }
    }

    .ant-tag {
        position: absolute;
        top: 8px;
        right: -8px;
        padding: 3px 10px;
        font-size: 16px;
        border-radius: 2px 0px 0px 2px;
    }
}

.ant-typography {
    width: 100%;

    &.center {
        display: block;
        text-align: center;
    }

    &.ant-typography-secondary {
        color: rgba(@dark, 0.8);
    }
}

.ant-menu-item {
    border-radius: 7px;
}

// Radio
.ant-radio-group {
    .ant-radio-button-wrapper-checked {
        &.attendance,
        &.attendance:hover {
            background: @success-color;
            border-color: @success-color;
        }
        &.absend,
        &.absend:hover {
            background: @warning-color;
            border-color: @warning-color;
        }
        &.absend2,
        &.absend2:hover {
            background: @error-color;
            border-color: @error-color;
        }
    }
}

// ant-alert
.ant-alert {
    margin-bottom: 10px;
    &.ant-alert-success {
        background-color: rgba(@success-color, 0.15);
    }
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    stroke: @success-color;
}

// hidden miliseconds column Timepicker
.ant-picker-content .ant-picker-time-panel-column {
    width: 70px;
    &::-webkit-scrollbar {
        width: 0px;
    }
}
.ant-picker-time-panel-column:nth-child(3) {
    width: 0px;
    overflow: hidden;
}

.ant-modal-mask {
    z-index: 1030;
}
.ant-modal-wrap {
    z-index: 1047;
}

.ant-modal-confirm-btns {
    width: 100%;
    text-align: center;
}

.ant-popover-buttons {
    text-align: center;
}

.ant-descriptions-header {
    margin-bottom: 5px;
}

.ant-image-preview-operations {
    background: @sub-color;
    height: 65px;
}

#gridContainer {
    padding-bottom: 50px;
}
.dx-datagrid-header-panel {
    position: absolute;
    bottom: 10px;
    height: 40px;
    line-height: 40px;
    width: calc(100% - 46px);
    background: @sub-color;
    color: @white;
    text-transform: uppercase;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
}

.child_groups {
    padding: 12px 12px 1px 12px;
    border-radius: 3px;
    position: relative;
    background: rgba(@sub-color, 0.1);
    border-left: 2px solid @sub-color;
}

.ant-btn-dangerous {
    background-color: @error-color;
    color: @white;
    &:focus {
        background-color: @error-color;
        color: @white;
    }
}
