.ox-profile {
    &__block {
        background-color: @white;
        border-radius: 7px;
        border: 1px solid rgba(@sub-color, 0.1);
        animation-name: fadeInLeft;
        animation-duration: 1s;
    }
    &__review {
        padding: 24px 0;
        margin-right: 24px;
        animation-name: tableFadeInTop;
        animation-duration: 1.5s;
        position: sticky;
        top: 0;
        img {
            border-radius: 999px;
        }
        > div {
            width: 100%;
        }

        .ant-progress {
            margin-bottom: 12px;
        }
    }

    &__activities {
        padding: 0 12px 24px 12px;
        .ant-descriptions {
            margin-left: 24px;
        }

        .ant-form {
            width: 100%;
            margin-top: 24px;
        }
    }

    &__contact {
        padding-left: 30%;
        margin-top: 12px;
        .anticon {
            font-size: 18px;
            margin-right: 5px;
        }
    }
    &__subjects {
        padding: 0px 12px;
        position: relative;
        .ox-profile__update {
            top: 0px;
            right: 12px;
        }
    }
    &__classes {
        padding: 0px 12px;
        position: relative;
        .ox-profile__update {
            top: 0px;
            right: 12px;
        }
    }

    &__achievements {
        padding: 0 12px;
        .anticon {
            font-size: 14px;
            margin-right: 5px;
        }

        .ant-tag {
            margin-bottom: 8px;
        }
    }

    .ant-card {
        width: 100%;

        .btn-back {
            color: @white;
            margin-right: 5px;
            &:hover {
                background-color: rgba(@white, 0.15);
            }
        }

        .ant-card-extra {
            // margin-right: 0px;
            .ant-btn {
                color: @white;
            }
        }
    }

    &__update {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 999px;
        border: 1px solid rgab(@primary-color, 0.3);
    }

    &__photo {
        position: relative;
        // width: 66%;
        .ant-image {
            border-radius: 999px;
        }
        .ant-btn {
            position: absolute;
            right: 20px;
            bottom: 20px;
            z-index: 2;
            border-radius: 50%;
        }
    }

    // custom ant
    .ant-descriptions-header {
        margin-bottom: 10px;
    }

    .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
        padding: 16px 24px;
    }

    &__inactive, &__download {
        width: 100%;
        height: 40px;
        position: absolute;
        bottom: -80px;
        opacity: 0.5;
        &:hover {
            opacity: 1;
        }
    }

    &__download {
        border-radius: 0px 0px 5px 5px;
        opacity: 1;
        bottom: -24px;
    }
}

.profile_center {
    &__cover {
        position: absolute;
        top: 0;
        left: 0;
        height: 50%;
        width: 100%;
        overflow: hidden;
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        &:before {
            transition: all 0.5s;
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            background: rgba(@sub-color, 0.5);
            z-index: 1;
        }

        &:hover::before {
            background: rgba(@sub-color, 0.8);
        }
    }

    &__heading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: @primary-color !important;
        z-index: 2;
        text-shadow: 1px 2px 2px @sub-color;
        .ant-typography {
            color: @primary-color;
            &.profile_center__name {
                font-size: 50px;
                margin-bottom: 0px;
            }
        }
    }

    &__edit {
        position: absolute;
        right: 12px;
        bottom: 12px;
        z-index: 3;
        .ant-btn {
            color: @white;

            opacity: 0.7;
        }
    }

    &__course {
        top: 50%;
        width: 100%;
        position: absolute;
        left: 0px;
        padding: 24px;
        .ant-typography {
            margin-bottom: 0px;
        }
    }
}

.delay-render-list .ant-card-head-title {
    padding: 12px 0px;
}

.delay-render-list__items {
    cursor: pointer;
    .btn-remove {
        position: absolute;
        top: 8px;
        right: 13px;
        opacity: 0.5;
        z-index: 7;
        &:hover {
            opacity: 1;
            background-color: rgba(@white, 0.5);
            color: rgba(@error-color, 0.8);
            border: 1px solid rgba(@error-color, 0.33);
        }
    }

    &.--user .ant-tag {
        position: absolute;
        bottom: 83px;
        left: 24px;
        opacity: 0.9;
        height: 32px;
    }

    .ant-card {
        &.unselect {
            opacity: 0.9;
        }

        &.selected {
            opacity: 1;
            border: 1px solid rgba(@sub-color, 0.8);
        }
    }
}

// contract
.contract-student {
    width: 100%;
    &__items {
        width: 100%;
        margin-bottom: 20px;
        .ant-progress {
            .ant-progress-bg {
                height: 15px !important;
            }
            .ant-progress-text {
                position: absolute;
                text-align: center;
                left: 0;
                top: 4px;
                width: 100%;
                height: 100%;
                color: @sub-color;
                font-size: 12px;
                line-height: 15px;
                font-weight: 500;
                margin-left: 0;
            }

            .ant-progress-outer {
                padding-right: 0;
                margin-right: 0;
            }
        }
    }
}

// study process student
.study_process {
    overflow-x: scroll;
    width: 100%;
    padding: 0 16px 10px;
    &__content {
        margin-top: 20px;
        padding-top: 12px;
        border-top: 1px solid @sub-color;
        > .--notyet {
            margin-left: 50px;
            &::after {
                position: absolute;
                content: "- - -";
                top: 50%;
                transform: translateY(-50%);
                left: -50px;
                font-weight: bold;
            }
        }
    }

    &__slot {
        position: relative;
        min-height: 120px;
        min-width: 200px;
        border: 1px solid rgba(@sub-color, 0.5);
        border-radius: 5px;
        margin-right: 30px;
        padding: 8px;
        background-color: rgba(@sub-color, 0.25);

        &__timestamp {
            position: absolute;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            top: -25px;
            text-align: center;
            width: fit-content;
            background-color: @white;
        }

        &__status {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            text-align: center;
            text-transform: uppercase;
        }

        &.--attendance {
            background-color: rgba(@success-color, 0.25);
            .study_process__slot__status {
                background-color: @success-color;
                color: @white;
            }
        }
        &.--absend {
            background-color: rgba(@error-color, 0.25);
            .study_process__slot__status {
                background-color: @error-color;
                color: @white;
            }
        }
        &.--late {
            background-color: rgba(@primary-color, 0.1);
            .study_process__slot__status {
                background-color: @primary-color;
                color: @white;
            }
        }
        &.--no-attendance {
            background-color: rgba(@primary-color, 0.1);
            opacity: 0.6;
            .study_process__slot__status {
                background-color: @primary-color;
                color: @white;
            }
        }
        &.--notyet {
            background-color: rgba(@sub-color, 0.1);
            opacity: 0.6;
            .study_process__slot__status {
                background-color: @sub-color;
                color: @white;
            }
        }
    }

    /* width */
    &::-webkit-scrollbar {
        height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(@sub-color, 1);
    }

    &:hover {
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(@sub-color, 0.66);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(@sub-color, 0.99);
        }
    }
}

.image-preview {
    &__items {
        width: 31%;
        text-align: center;
        margin-bottom: 15px;

        .ant-btn {
            width: 100%;
            height: 100%;
            background: rgba(@sub-color, 0.05);
        }
    }
}
