.slot_card {
    position: relative;
    padding-left: 15px;
    .ant-card-body {
        padding: 16px;
    }

    &__content {
        display: block;
        max-width: calc(100% - 220px);
    }
    &__status {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0px;
        font-weight: 500;
        writing-mode: vertical-lr;
        text-align: center;
        text-transform: uppercase;
        background: @info-color;
    }
    &.pending {
        background-color: rgba(@primary-color, 0.2);
        .slot_card__status {
            background-color: @primary-color;
            color: @white;
        }
    }
    &.closed {
        background-color: rgba(@sub-color, 0.3);
        cursor: not-allowed;
        opacity: 0.6;
        .slot_card__status {
            background-color: @sub-color;
            color: @white;
        }
    }
    &.open {
        background-color: rgba(@info-color, 0.2);
        .slot_card__status {
            background-color: @info-color;
        }
    }
    &__label {
        position: absolute;
        height: 100%;
        width: 70px !important;
        left: 0;
        top: 0;
        background-color: @sub-color;

        &__name {
            font-size: 16px;
        }

        span {
            color: @white;
        }
        &__slot {
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        &__number {
            font-size: 28px;
            line-height: 1;
        }
    }

    &__time {
        position: absolute;
        right: 0px;
        width: 200px;
        height: 100%;
        top: 0;
        display: flex;
        flex-direction: column;
        .ant-typography {
            position: relative;
            height: 50%;
            line-height: 100%;
            padding: 0px 12px;
            &:first-child {
                background-color: rgba(@success-color, 0.2);
            }
            &:last-child {
                background-color: rgba(@error-color, 0.2);
            }
        }
    }

    &__actions {
        position: absolute;
        height: 100%;
        top: 0;
        right: 215px;
    }

    &__exam {
        position: absolute;
        height: 50px;
        top: 5px;
        right: 205px;
    }
}

.classes_modal_select {
    .ant-modal-body {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    &__items {
        padding: 0 12px;
        cursor: pointer;
        width: 45%;
        height: 70px;
        border-radius: 10px;
        border: 1px solid rgba(@sub-color, 0.33);
        border-width: 1px 1px 3px 1px;
        box-shadow: 0 1px 2px -2px rgba(@white, 0.16), 0 3px 6px 0 rgba(@white, 0.12), 0 5px 12px 4px rgba(@white, 0.1);
        transition: all 0.33s ease;
        .anticon {
            font-size: 28px;
            margin-right: 12px;
            color: rgba(@sub-color, 0.8);
        }

        &:hover {
            box-shadow: 0 1px 2px -2px rgba(@sub-color, 0.16), 0 3px 6px 0 rgba(@sub-color, 0.12), 0 5px 12px 4px rgba(@sub-color, 0.1);
        }
    }
}

.slot_modal {
    &__time {
        // height: 40px;
        // border: 1px solid rgba(@error-color, 0.2);
        border-radius: 5px;
        overflow: hidden;
        &__from,
        &__to {
            height: 50px;
            line-height: 50px;
            text-align: center;
            position: relative;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 2px;
            color: @white;
            font-family: "Secular One";
        }
        &__from {
            // height: 50px;
            width: 48%;
            z-index: 2;
            background-color: @success-color;

            &::after {
                position: absolute;
                content: "";
                height: 0;
                width: 0;
                top: 0;
                right: -20px;
                border: 25px solid @success-color;
                transform: rotate(-45deg);
                box-shadow: 2px 2px 0px 2px white;
                border-radius: 2px;
            }
        }

        &__to {
            // height: 50px;
            width: 52%;
            z-index: 1;
            background-color: @error-color;
        }
    }
    .ant-steps {
        margin-top: 12px;
    }
}

.radio-attendance {
    margin-left: 24px;
}
