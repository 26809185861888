.popover_noti {
  width: 500px;
  .ant-alert {
    transition: all 0.33s;
    padding: 10px 7px;
    cursor: pointer;
    &.readed {
      opacity: 0.5;
    }
    &.unread {
      border-width: 1px 1px 1px 3px;
    }
  }

  .ant-alert:hover {
    opacity: 1;
  }

  .ant-typography {
    transform: rotate(-45deg);
    display: block;
    margin-top: 14px;
  }
  .ant-popover-inner-content {
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
      height: 100%;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: @primary-color;
      border-radius: 4px;
    }
  }
}

.ox_notification {
  width: 500px;
  padding: 0px;
  background: transparent;
  .ant-alert {
    margin-bottom: 0px;
  }
}
