.oxbridge_login {
  height: 100%;
  background-color: rgba(@primary-color, 0.1);
  .ant-form {
    background-color: @white;
    width: 400px;
    border: 1px solid @primary-color;
    border-width: 1px 1px 3px 1px;
    padding: 25px 50px;
    border-radius: 7px;
    .login-form-forgot {
      float: right;
    }
    .ant-btn {
      height: 45px;
    }
    .ant-select {
      float: right;
    }

    .ant-input-group-addon:first-child {
      background: @sub-color;
      border-color: @sub-color;
      color: @white;
    }
  }

  img {
    margin-bottom: 50px;
  }

  &__otp {
    .ant-input-number {
      border-width: 1px 1px 5px 1px;
      border-color: rgba(@primary-color, 0.33);
      height: 85px;
      width: 100%;
      margin-top: 20px;
      input {
        text-align: center;
        font-size: 60px;
        font-weight: 400;
        line-height: 70px;
        height: 80px;
        color: @primary-color;
        font-family: monospace;
        letter-spacing: 10px;
      }
      &:hover,
      &:focus {
        border-color: @primary-color;
      }
    }
    .ant-input-number-focused {
      border-color: @primary-color;
    }
  }
}
