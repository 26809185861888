.dashboard_calendar {
    .fc .fc-daygrid-event-harness-abs {
        margin-bottom: 12px;
    }
    .fc .fc-daygrid-day.fc-day-today {
        background-color: rgba(@success-color, 0.11);
    }
    .fc-h-event,
    .fc-v-event,
    .fc-daygrid-event-harness .fc-daygrid-event {
        background-color: rgba(@info-color, 0.7);
        border-color: rgba(@info-color, 0.9);
        border-width: 1px 2px 3px 1px;
        padding: 5px;
        transition: 0.25s ease;
        position: relative;
        .event__title {
            font-size: 14px;
            font-weight: 600;
        }
        .ant-typography {
            white-space: normal;
        }

        &:hover {
            background-color: rgba(@info-color, 0.99);
        }
    }

    .fc-timegrid-event-harness-inset .fc-timegrid-event,
    .fc-timegrid-event.fc-event-mirror,
    .fc-timegrid-more-link {
        box-shadow: none;
    }

    .fc .fc-non-business {
        background-color: rgba(@sub-color, 0.11);
    }
}
