@keyframes fadeIn {
    0% {
        transform: translateY(-25%);
    }
    50% {
        transform: translateY(4%);
    }
    65% {
        transform: translateY(-2%);
    }
    80% {
        transform: translateY(2%);
    }
    95% {
        transform: translateY(-1%);
    }
    100% {
        transform: translateY(0%);
    }
}

.popup {
    animation-name: fadeIn;
    animation-duration: 0.4s;
}

@keyframes zoomIn {
    0% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

// .ant-btn:hover,
// .ant-menu-item:hover {
//     animation-name: zoomIn;
//     animation-duration: 0.33s;
// }

@keyframes tableFadeInTop {
    0% {
        top: 50px;
    }
    50% {
        top: 0px;
    }
}
@keyframes fadeInLeft {
    0% {
        left: 50px;
    }
    50% {
        left: 0px;
    }
}
@keyframes fadeOut {
    0% {
        left: 0px;
        opacity: 1;
    }
    50% {
        left: 50px;
        opacity: 0;
    }
}

@keyframes cardFadeInTop {
    0% {
        top: 30px;
        opacity: 0.5;
    }
    50% {
        top: 0px;
    }
}
@keyframes calendarFadeInTop {
    0% {
        top: 10px;
    }
    50% {
        top: 0px;
    }
}

.ant-table-tbody,
.ant-modal-content,
.oxbridge_login .ant-form {
    position: relative;
    animation-delay: 0s;
    animation-name: tableFadeInTop;
    animation-duration: 1.5s;
}
.ant-table-thead {
    position: relative;
    animation-name: tableFadeInTop;
    animation-duration: 0.7s;
}

.delay-render-list {
    &__items {
        position: relative;
        animation-name: cardFadeInTop;
        animation-duration: 0.5s;
    }
}

.fc-daygrid-event {
    animation-name: calendarFadeInTop;
    animation-duration: 0.5s;
}

.ant-tabs-tabpane {
    position: relative;
    animation-name: fadeInLeft;
    animation-duration: 1s;
}
